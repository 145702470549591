.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}

.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  /*margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;*/
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  /*border-top-left-radius: 0;
  border-top-right-radius: 0;*/
}

/*.form-signin .google-recaptcha {
  margin-bottom: 10px;
}*/

.form-signin div.google-recaptcha.is-invalid > div > div {
  border-radius: .25rem!important;
  border: 1px solid #dc3545!important;
}
    
