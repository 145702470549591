.form-change-password {
    /*width: 100%;
    max-width: 400px;
    padding: 15px;
    margin: auto;*/
}

.form-change-password .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-change-password .form-control:focus {
  z-index: 2;
}

