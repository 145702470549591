.table-sortable .header{
	cursor:pointer;
}

.table-sortable .header:after{
	content:"";
	float:right;
	margin-top:7px;
	border-width:0 4px 4px;
	border-style:solid;
	border-color:#000 transparent;
	visibility:hidden;
}

.table-sortable .headerSortUp,.table-sortable .headerSortDown{
	background-color:rgba(141, 192, 219, 0.25);
	text-shadow:0 1px 1px rgba(255, 255, 255, 0.75);

}

.table-sortable .header:hover:after{
	visibility:visible;
}

.table-sortable .headerSortDown:after,.table-sortable .headerSortDown:hover:after{
	visibility:visible;filter:alpha(opacity=60);-khtml-opacity:0.6;-moz-opacity:0.6;opacity:0.6;
}

.table-sortable .headerSortUp:after{
	border-bottom:none;
	border-left:4px solid transparent;
	border-right:4px solid transparent;
	border-top:4px solid #000;
	visibility:visible;
	-webkit-box-shadow:none;
	-moz-box-shadow:none;
	box-shadow:none;
	filter:alpha(opacity=60);
	-khtml-opacity:0.6;
	-moz-opacity:0.6;
	opacity:0.6;
}

.inactive-row {
    text-decoration: line-through;
}

.table-row-item-overflow {
    text-overflow: ellipsis;
    overflow : hidden;
    white-space: nowrap;
    max-width: 450px;
}

.table-row-item-overflow:hover {
    text-overflow: clip;
    white-space: normal;
    word-break: break-all;
    max-width: 450px;
}