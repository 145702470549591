.thumbnail {
    background-color: #f8f8f8 !important;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 15%);
    cursor: pointer;
}

.thumb-title {
    text-overflow: ellipsis;
    overflow : hidden;
    white-space: nowrap;
}

.thumb-title:hover {
    text-overflow: clip;
    white-space: normal;
    word-break: break-all;
}

.thumb-subtitle {
    text-overflow: ellipsis;
    overflow : hidden;
    white-space: nowrap;
}

.thumb-subtitle:hover {
    text-overflow: clip;
    white-space: normal;
    word-break: break-all;
}

.thumb-text {
    font-size: 80%;
    text-overflow: ellipsis;
    overflow : hidden;
    white-space: nowrap;
}

.thumb-text:hover {
    font-size: 80%;
    text-overflow: clip;
    white-space: normal;
    word-break: break-all;
}
